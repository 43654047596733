import { Link } from "gatsby"
import React from "react"

const Why = () => (
  <section className="why-choose-us">
    <div className="container">
      <div className="grid center-text">
        <div className="column-xs-12">
          <h4>Why Choose Us?</h4>
          <h2>Luis's Painting is committed to providing top quality of workmanship.</h2>
          <p>Selecting Luis's Painting will be guaranteed with the highest standards of skillful workmanship from the beginning to the end of any assigned project.</p>
        </div>
      </div>
      <div className="grid center-text-full">
        <div className="column-xs-12 column-sm-6">
          <div className="item">
            <div className="circle" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m16 2.672-5.331 5.331V5.87H6.404v6.398l-3.755 3.755.754.754L16 4.18l12.597 12.597.754-.754L16 2.672zM7.47 6.937h2.132v2.132l-2.133 2.133V6.937z"/><path d="M6.404 16.533v12.795h7.464v-7.464h4.265v7.464h7.464V16.533l-9.596-9.596-9.596 9.596zM24.53 28.262h-5.331v-7.464h-6.398v7.464H7.47V16.975L16 8.445l8.53 8.53v11.287z"/></svg>
            </div>
            <div className="details">
              <h3>Locally owned</h3>
              <p>We are not a franchise. Luis's Painting is a owner-operated painting company with more than 20 years of experience providing services in the New Jersey, New York, and Indiana.</p>
            </div>
          </div>
        </div>
        <div className="column-xs-12 column-sm-6">
          <div className="item">
            <div className="circle" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 32"><path d="M6.294 14.164h12.588v1.049H6.294v-1.049zm0 4.196h12.588v1.049H6.294V18.36zm0 4.197h8.392v1.049H6.294v-1.049z"/><path d="M15.688 3.674c-.25-1.488-1.541-2.623-3.1-2.623s-2.85 1.135-3.1 2.623H-.001v27.275h25.176V3.674h-9.488zM10.49 6.082V4.198c0-1.157.941-2.098 2.098-2.098s2.098.941 2.098 2.098v1.884l.531.302a5.328 5.328 0 0 1 2.273 2.535H7.687A5.328 5.328 0 0 1 9.96 6.384l.53-.302zM24.128 29.9H1.05V4.723h8.392v.749a6.395 6.395 0 0 0-3.147 4.496h12.588a6.394 6.394 0 0 0-3.147-4.496v-.749h8.392V29.9z"/></svg>
            </div>
            <div className="details">
              <h3>Free estimates</h3>
              <p>It is important to know the details of a project ahead of time. We always offer free estimates no matter the size of the project.</p>
            </div>
          </div>
        </div>
        <div className="column-xs-12 column-sm-6">
          <div className="item">
            <div className="circle" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="m29.532 25.76-5.655-5.655.754-.754-.754-.754-2.261 2.261-3.771-3.77 4.53-4.532a5.579 5.579 0 0 0 1.882.324 5.562 5.562 0 0 0 3.954-1.637 5.569 5.569 0 0 0 1.178-6.174l-.311-.722-2.43 2.43-1.956.027.026-1.866 2.477-2.477-.72-.312a5.576 5.576 0 0 0-2.229-.461 5.552 5.552 0 0 0-3.952 1.637 5.56 5.56 0 0 0-1.311 5.84l-4.529 4.529-6.409-6.408.754-.754-4.145-4.146L2.39 4.647l4.147 4.147.753-.754 6.409 6.408-4.529 4.529a5.57 5.57 0 0 0-1.888-.326 5.558 5.558 0 0 0-3.953 1.637 5.565 5.565 0 0 0-1.176 6.181l.312.72 2.477-2.477 1.865-.025-.027 1.956-2.43 2.43.722.311a5.59 5.59 0 0 0 2.221.458 5.551 5.551 0 0 0 3.952-1.636 5.565 5.565 0 0 0 1.314-5.833l4.532-4.532 3.771 3.769-2.263 2.263.754.754.754-.754 5.654 5.654c.503.504 1.174.781 1.885.781s1.381-.277 1.885-.781a2.668 2.668 0 0 0 0-3.769zM3.899 4.648l.754-.753 2.638 2.638-.754.754-2.639-2.639zm7.549 17.808a4.513 4.513 0 0 1-.955 4.999 4.491 4.491 0 0 1-3.198 1.324c-.347 0-.689-.039-1.021-.116l1.569-1.569.047-3.485-3.394.046-1.619 1.619a4.497 4.497 0 0 1 1.208-4.229 4.495 4.495 0 0 1 3.199-1.325c.626 0 1.233.125 1.806.373l.333.144L20.242 9.418l-.144-.333a4.505 4.505 0 0 1 .952-5.004 4.494 4.494 0 0 1 3.198-1.325c.35 0 .695.04 1.03.117L23.66 4.491l-.047 3.394 3.485-.047 1.57-1.57a4.504 4.504 0 0 1-1.209 4.221 4.499 4.499 0 0 1-3.2 1.325c-.624 0-1.23-.125-1.801-.371l-.332-.143-10.821 10.823.143.332zm17.331 6.319c-.302.302-.704.469-1.131.469s-.829-.167-1.131-.469l-5.654-5.654 2.262-2.262 5.655 5.655a1.6 1.6 0 0 1 .001 2.261z"/></svg>
            </div>
            <div className="details">
              <h3>Owner on every job</h3>
              <p>To guarantee you are receiving the highest quality of service, all projects are run and supervised by the owner himself.</p>
            </div>
          </div>
        </div>
        <div className="column-xs-12 column-sm-6">
          <div className="item">
            <div className="circle" aria-hidden="true">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M27.995 5.338V3.684a2.611 2.611 0 0 0-2.611-2.611H8.217a2.61 2.61 0 0 0-2.61 2.611v1.654h-.489A2.178 2.178 0 0 0 2.94 7.517v3.649c0 1.203.975 2.179 2.178 2.179l10.037-.003c.613 0 1.113.499 1.113 1.113v3.679h-2.131v12.795h5.331V18.134h-2.134v-3.679a2.18 2.18 0 0 0-2.179-2.179h-1.32v.003H5.119a1.114 1.114 0 0 1-1.112-1.113V7.517c0-.613.499-1.113 1.112-1.113h.489v1.654a2.61 2.61 0 0 0 2.61 2.611h17.167a2.611 2.611 0 0 0 2.611-2.611V6.404h1.066V5.338h-1.066zM18.4 29.861h-3.199V19.198H18.4v10.663zm8.529-21.803c0 .852-.694 1.545-1.545 1.545H8.217a1.546 1.546 0 0 1-1.544-1.545V3.684c0-.852.692-1.545 1.544-1.545h17.167c.852 0 1.545.693 1.545 1.545v4.374z"/></svg>
            </div>
            <div className="details">
              <h3>Quality work</h3>
              <p>Our team is equipped with only the best tools and paints for every job to guarantee the highest quality of workmanship.</p>
            </div>
          </div>
        </div>
        <div className="column-xs-12 column-sm-6">
          <div className="item">
            <div className="circle">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 32"><path d="M12.8 3.2C5.707 3.2 0 8.907 0 16s5.707 12.8 12.8 12.8c7.093 0 12.8-5.707 12.8-12.8S19.893 3.2 12.8 3.2zm0 24.533c-6.453 0-11.733-5.28-11.733-11.733S6.347 4.267 12.8 4.267 24.533 9.547 24.533 16 19.253 27.733 12.8 27.733z"/><path d="M19.467 19.04c-.267-.107-.587-.053-.693.213-1.173 2.293-3.467 3.68-5.973 3.68-2.56 0-4.8-1.387-5.973-3.68-.107-.267-.427-.373-.693-.213-.267.107-.373.427-.267.693C7.201 22.346 9.815 24 12.801 24c2.933 0 5.6-1.653 6.88-4.267.16-.267.053-.587-.213-.693zm-9.334-5.707a1.6 1.6 0 1 1-3.2 0 1.6 1.6 0 0 1 3.2 0zm8.534 0a1.6 1.6 0 1 1-3.2 0 1.6 1.6 0 0 1 3.2 0z"/></svg>
            </div>
            <div className="details">
              <h3>Reliable personnel</h3>
              <p>We have skilled and dedicated staff to provide you with exceptional painting and handyman services for all of your projects.</p>
            </div>
          </div>
        </div>
        <div className="column-xs-12 column-sm-6">
          <div className="item">
            <div className="circle">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32"><path d="M27.275 15.638c0-1.288-1.02-2.282-2.374-2.282h-8.536c.545-1.265 1.524-3.919 1.524-6.659 0-3.128-1.569-3.784-2.886-3.784-.816 0-1.996.619-1.996 1.737 0 .472-.075 4.671-2.639 6.822-2.732 2.292-3.509 2.891-5.005 2.891-1.746 0-4.847.038-4.847.038l-.517.007v12.206h5.712c.371 0 1.483.496 2.466.932 1.622.722 3.459 1.541 4.696 1.541l8.25-.003c1.343 0 2.436-1.056 2.436-2.354 0-.529-.189-1.012-.494-1.405a2.35 2.35 0 0 0 1.711-2.254c0-.539-.19-1.031-.496-1.429a2.397 2.397 0 0 0 1.677-2.277c0-.538-.184-1.031-.485-1.431a2.372 2.372 0 0 0 1.801-2.295zm-2.374 1.324h-1.822v1.049h.477c.746 0 1.353.607 1.353 1.353s-.608 1.353-1.353 1.353H22.03v1.049h.394a1.306 1.306 0 0 1 0 2.61h-1.443v1.049h.143c.764 0 1.387.586 1.387 1.305 0 .72-.623 1.305-1.387 1.305h-1.34v.003h-6.91c-1.014 0-2.819-.804-4.27-1.45-1.425-.634-2.326-1.023-2.892-1.023H1.049V15.444c.955-.011 3.013-.032 4.315-.032 1.94 0 2.959-.854 5.679-3.136 2.868-2.406 3.014-6.774 3.014-7.626 0-.428.657-.688.947-.688.549 0 1.837 0 1.837 2.735 0 3.456-1.745 6.91-1.762 6.945l-.39.763h6.426c.018.013.03 0 .03 0h3.757c.767 0 1.325.532 1.325 1.233 0 .73-.594 1.324-1.325 1.324z"/></svg>
            </div>
            <div className="details">
              <h3>Afforable rates</h3>
              <p>Whether you have worked with us before or not, Luis's Painting works with you to provide the best prices on any budget.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid center-text">
        <div className="column-xs-12">
          <Link to='/services' className="button" role="button">View Our Services <span className="icon arrow" aria-hidden="true"></span></Link>
        </div>
      </div>
    </div>
  </section>
)

export default Why
