import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Why from "../components/why"
import Cta from "../components/cta"

class Gallery extends React.Component {
  render() {
    const data = this.props.data
    const imageOne = data.imageOne.childImageSharp.gatsbyImageData
    const imageTwo = data.imageTwo.childImageSharp.gatsbyImageData
    const imageThree = data.imageThree.childImageSharp.gatsbyImageData
    const imageFour = data.imageFour.childImageSharp.gatsbyImageData
    const imageFive = data.imageFive.childImageSharp.gatsbyImageData
    const imageSix = data.imageSix.childImageSharp.gatsbyImageData
    const imageSeven = data.imageSeven.childImageSharp.gatsbyImageData
    const imageEight = data.imageEight.childImageSharp.gatsbyImageData

    const Gallery = [
      {
        image: imageOne,
        alt: "Deck services by Luis's Painting"
      }, {
        image: imageTwo,
        alt: "Heat covers by Luis's Painting"
      }, {
        image: imageThree,
        alt: "Residential painting services by Luis's Painting"
      }, {
        image: imageFour,
        alt: "Handyman services by Luis's Painting"
      }, {
        image: imageFive,
        alt: "Power washing services by Luis's Painting"
      }, {
        image: imageSix,
        alt: "Handyman services by Luis's Painting"
      }, {
        image: imageSeven,
        alt: "Commercial painting services by Luis's Painting"
      }, {
        image: imageEight,
        alt: "Handyman services by Luis's Painting"
      }
    ]

    return (
      <Layout>
        <Seo title="Gallery" />
        <section className="services">
          <div className="container">
            <div className="grid">
              <div className="column-xs-12">
                <h4>Experienced and Trusted Painting Company</h4>
                <h2>We strive to deliver excellence in quality and personalized services with the highest level of integrity for every project.</h2>
                <p>Luis's Painting have been in business for over 20 years with outstanding results and satisfied clients. We provide the level of detail and professionalism you expect and deserve. We take great pride in all of our work we have done. Please take the time to browse the images below as a sampling of our work.</p>
              </div>
            </div>
            <div className="grid">
            {Gallery.map(work => (
              <div className="column-xs-12 column-sm-6">
                <GatsbyImage image={work.image} alt={work.alt} />
              </div>
            ))}
            </div>
          </div>
      </section>
      <Why />
      <Cta />
      </Layout>
    )
  }
}

export default Gallery

export const pageQuery = graphql`
  query GalleryQuery {
    imageOne: file(
      relativePath: { regex: "/deck.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageTwo: file(
      relativePath: { regex: "/heater-cover.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageThree: file(
      relativePath: { regex: "/dining2.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageFour: file(
      relativePath: { regex: "/staircase.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageFive: file(
      relativePath: { regex: "/deck2.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSix: file(
      relativePath: { regex: "/kitchen.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSeven: file(
      relativePath: { regex: "/dining.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageEight: file(
      relativePath: { regex: "/heater-cover2.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
