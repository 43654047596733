import React from "react"
import { Link } from "gatsby"

const Cta = () => (
  <section className="cta">
    <div className="container">
      <div className="grid center-text">
        <div className="column-xs-12">
          <h4>Let's Work Together</h4>
          <h2>Ready to get started?</h2>
          <p className="lead">Contact the professionals at Luis's Painting today to receive a free estimate at no obligation and begin your projects with us.</p>
          <Link to='/contact' className="button" role="button">Get a Free Estimate <span className="icon arrow" aria-hidden="true"></span></Link>
        </div>
      </div>
    </div>
  </section>
)

export default Cta